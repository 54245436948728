






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ApiCommon } from '@/scripts/apiCommon.ts';
import * as lib from '@/scripts/apiHelper.ts';
import { IProduct } from '@/scripts/models';
import Product from '@/components/Products/Product.vue';

@Component({ components: { Product } })
export default class Products extends Vue {
  @Prop({ required: false, default: false })
  private brief!: boolean;

  private snackbarShow = false;
  private products: IProduct[] = [];
  private productErrorMessage = '';
  private selectedProduct = {} as IProduct;

  private async mounted(): Promise<void> {
    try {
      this.products = await ApiCommon.getProducts();
    } catch (error) {
      this.productErrorMessage = lib.getErrorMessage(error);
    }
  }

  // Select product
  private productClicked(value: IProduct): void {
    this.selectedProduct = value;
  }

  // Order button clicked - emit product
  private orderClicked(): void {
    if (this.selectedProduct && this.selectedProduct.ProductId > 0) {
      this.$emit('order-clicked', this.selectedProduct);
    } else {
      this.snackbarShow = true;
    }
  }
}
