


















import { Component, Vue } from 'vue-property-decorator';
import Products from '@/components/Products/Products.vue';
import SignupWizard from '@/components/SignupWizard.vue';
import { IProduct } from '@/scripts/models';

@Component({ components: { SignupWizard, Products } })
export default class ProductPage extends Vue {
  // Order button clicked - open signup wizard
  private orderClicked(value: IProduct): void {
    (this.$refs.signupWizard as SignupWizard).showSignupWizard(value);
  }
}
